import BaseComponent from "../base";

export class BulmaFileInputManager extends BaseComponent
{
    public static readonly selector = '[data-hook-file-input-manager]';
    private fileInput: HTMLInputElement;
    private fileNameContainer: HTMLElement;
    private previewContainer: HTMLImageElement;

    constructor(element: HTMLElement)
    {
        super(element);
        this.init();
    }

    private init()
    {
        this.fileInput = this.element.querySelector('[data-is-file-input]');
        this.fileNameContainer = this.element.querySelector('[data-is-file-name-container]');
        this.previewContainer = this.element.querySelector('[data-is-preview-container]');
        this.bindEvents();
    }

    private bindEvents()
    {
        this.fileInput.addEventListener('change', this.onFileInputChange);
    }

    private onFileInputChange = (e: Event) =>
    {
        if (this.fileInput.files.length > 0)
        {
            let file = this.fileInput.files[0];
            if (this.fileNameContainer != null)
            {
                this.fileNameContainer.innerText = file.name;
            }

            if (this.previewContainer != null)
            {
                let reader = new FileReader();
                reader.addEventListener('load', () =>
                {
                    this.previewContainer.src = reader.result;
                });

                reader.readAsDataURL(file);
            }
        }
    }
}