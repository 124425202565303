import BaseComponent from "../base";
import { findParentElement } from "../../helpers/dom";

export class FormsetManager extends BaseComponent
{
    public static readonly selector = '[data-hook-formset-manager]';
    public static attributes = {
        isFormsetForm: 'data-is-formset-form'        
    };
    public static readonly selectors = {
        formsetEmptyForm: '[data-is-formset-empty-form]',
        formsetAddForm: '[data-is-formset-add-form-button]',
        formsetDeleteForm: '[data-is-formset-delete-form-button]',
        formsetFormContainer: '[data-is-formset-form-container]',
        formsetDeleteField: '[data-is-formset-form-delete-field]'
    };
    public static readonly deleteButtonSelector = '[data-is-formset-delete-form-button]';

    private prefix: string;

    private emptyTemplateElement: HTMLScriptElement;
    private addFormButton: HTMLButtonElement;
    private emptyTemplate: string;
    private formsContainer: HTMLElement;
    private totalFormsElement: HTMLInputElement;


    private _formCount: number = 0;
    private get formCount(): number { return this._formCount; }
    private set formCount(value: number)
    {
        this._formCount = value;
        this.totalFormsElement.setAttribute('value', this._formCount.toString());
    }

    constructor(element: HTMLElement)
    {
        super(element);
        this.init();
        this.bindEvents();
    }

    private init()
    {
        this.prefix = this.element.getAttribute('data-prefix') || 'form';
        this.emptyTemplateElement = this.element.querySelector(FormsetManager.selectors.formsetEmptyForm);
        this.emptyTemplate = this.emptyTemplateElement.innerHTML;
        this.addFormButton = this.element.querySelector(FormsetManager.selectors.formsetAddForm);
        this.formsContainer = this.element.querySelector(FormsetManager.selectors.formsetFormContainer);
        this.totalFormsElement = this.element.querySelector(`#id_${this.prefix}-TOTAL_FORMS`);
        this.formCount = parseInt(this.totalFormsElement.getAttribute('value'));
    }

    private bindEvents()
    {
        this.addFormButton.addEventListener('click', this.onAddForm);
  
        // Add listeners to existing delete buttons
        var existingDeleteButtons = this.element.querySelectorAll(FormsetManager.deleteButtonSelector);
        for(var button of existingDeleteButtons)
        {
            button.addEventListener('click', this.onDeleteForm);
        }
    }

    private onAddForm = (e: MouseEvent) =>
    {
        let newForm = document.createElement('div');
        newForm.innerHTML = this.emptyTemplate.replace(/__prefix__/g, this.formCount.toString());
        
        let formNumberElement = newForm.querySelector('[data-is-formset-form-index-number]');
        if (formNumberElement != null)
        {
            formNumberElement.textContent = (this.formCount + 1).toString();
        }

        this.formsContainer.appendChild(newForm);

        let deleteButton = newForm.querySelector(FormsetManager.deleteButtonSelector);
        if (deleteButton != null)
        {
            deleteButton.addEventListener('click', this.onDeleteForm);
        }

        this.formCount++;
    }

    private onDeleteForm = (e: MouseEvent) =>
    {
        let deleteButton = e.currentTarget as HTMLElement;
        let formset = findParentElement(deleteButton, element => element.hasAttribute(FormsetManager.attributes.isFormsetForm));

        let deleteField = formset.querySelector(FormsetManager.selectors.formsetDeleteField) as HTMLInputElement;
        deleteField.value = 'on';

        formset.classList.add('is-hidden');
    }
}