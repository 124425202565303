export function findParentElement(element: HTMLElement, predicate: (element: HTMLElement) => boolean): HTMLElement | null
{
    while (true)
    {
        element = element.parentElement;
        if (element != null && predicate(element))
        {
            return element;
        }
        else if(element == null)
        {
            return null;
        }
    }
};