import { BulmaFileInputManager } from "../../core/frontend/ts/components/bulma/file-input-manager";

export default class TournamentHub
{
    private pollUpdates = true;

    get tournamentHubWrapper()
    {
        return document.getElementById('tournament-hub-wrapper');
    }

    get tournamentHubContent()
    {
        return document.getElementById('tournament-hub-content');
    }

    get currentMatch()
    {
        return document.getElementById('current-match');
    }

    get stateId()
    {
        return this.tournamentHubContent.getAttribute('data-state-id');
    }

    constructor()
    {
        this.queueUpdate();
        this.hookForm();
    }

    private pollUpdatesAndQueueNext = () =>
    {
        fetch(`?state_id=${this.stateId}`, {
            method: 'GET', 
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((response) =>
        {
            return response.text();
        })
        .then((html) =>
        {
            if (!this.pollUpdates)
            {
                return;
            }

            if (html.indexOf('/') == 0)
            {
                window.location.href = html;
                return;
            }
            else if (html.length > 0)
            {
                let scrollY = window.scrollY;
                let isCountdownUpdate = html.indexOf('data-is-countdown-update') != -1;
                if (!isCountdownUpdate)
                {
                    this.tournamentHubWrapper.classList.add('is-updated');
                }
                window.setTimeout(() =>
                {
                    this.tournamentHubWrapper.innerHTML = html;
                    this.hookForm();
                    
                    BulmaFileInputManager.registerAllNow();
                    
                    if (!isCountdownUpdate)
                    {
                        window.scrollTo({
                            top: scrollY,
                            behavior: 'smooth'
                        });
                    }

                    window.setTimeout(() =>
                    {
                        this.tournamentHubWrapper.classList.remove('is-updated');
                    }, 200);
                }, 330);
            }
            else
            {
            }
            
            this.queueUpdate();
        }).catch((error) =>
        {
            window.setTimeout(() =>
            {
                window.location.reload();
            }, 5000);
        });
    };

    private queueUpdate()
    {
        window.setTimeout(this.pollUpdatesAndQueueNext, 1000);
    }

    private hookForm()
    {
        let form = this.tournamentHubContent.querySelector('form');
        form.addEventListener('submit', (e) =>
        {
            if (form.hasAttribute('data-no-ajax'))
            {
                console.log('form is synchronous');
                this.pollUpdates = false;
                return true;
            }

            e.preventDefault();

            let button = form.querySelector('button[type="submit"]') as HTMLButtonElement;
            button.classList.add('is-loading');
            button.disabled = true;
            

            const data = new FormData(form);
            data.append(button.name, button.value);

            fetch(form.action, {
                method: 'POST',
                body: data,
                credentials: 'same-origin'
            }).then((response) =>
            {
                console.log('form success!')
            });
        });
    }
}