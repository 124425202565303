import BaseComponent from "../base";

export default class ClipboardButtonManager extends BaseComponent
{
    public static readonly attributeName = 'data-hook-clipboard-copy';
    public static readonly selector = `[${ClipboardButtonManager.attributeName}]`;

    private inputField: HTMLInputElement;

    constructor(element: HTMLElement)
    {
        super(element);
        this.init();
        this.bindEvents();
    }

    private init()
    {
        let selector = this.element.getAttribute('data-clipboard-input-selector');

        if (selector != null)
        {
            this.inputField = document.querySelector(selector);
        }
    }

    private bindEvents()
    {
        this.element.addEventListener('click', this.onClicked);
    }

    private onClicked = (e: MouseEvent) =>
    {
        let data = this.element.getAttribute(ClipboardButtonManager.attributeName);

        if (this.inputField != null)
        {
            console.log('copy')
            this.inputField.value = data;
            this.inputField.select();
            document.execCommand('copy');
        }
    };
}