import BaseComponent from "../base";
import { setTimeout } from "timers";

export default class MessageManager extends BaseComponent
{
    public static readonly selector = '[data-hook-message-manager]';
    public static readonly progressBarAnimationLength = 60 * 4;
    public static readonly closeAnimationLength = 60 * 0.5;
    
    private progressElement: HTMLProgressElement;
    private closeButton: HTMLElement;

    private progressBarAnimationTicks: number;
    private closeAnimationTicks: number;
    private isClosing: boolean;
    private isPaused: boolean;
    private disableTimer: boolean;

    constructor(element: HTMLElement)
    {
        super(element);
        this.isClosing = false;
        this.isPaused = false;
        this.progressBarAnimationTicks = MessageManager.progressBarAnimationLength;
        this.closeAnimationTicks = MessageManager.closeAnimationLength;
        this.init();
    }

    private init()
    {
        this.disableTimer = this.element.hasAttribute('data-no-timer');
        this.progressElement = this.element.querySelector('[data-is-message-progress]');
        this.closeButton = this.element.querySelector('[data-is-message-close-button]');
        this.bindEvents();

        if (!this.disableTimer)
        {
            this.displayTick();
        }
    }

    private bindEvents()
    {
        this.closeButton.addEventListener('click', this.onCloseButtonClicked);
        //this.element.addEventListener('mouseenter', this.onMouseEnter);
        //this.element.addEventListener('mouseleave', this.onMouseLeave);
    }

    private onMouseEnter = (e: MouseEvent) =>
    {
        this.isPaused = true;
    };

    private onMouseLeave = (e: MouseEvent) =>
    {
        this.isPaused = false;
    };

    private onCloseButtonClicked = (e: MouseEvent) =>
    {
        this.element.classList.add('is-closing');
        window.setTimeout(() => this.remove(), 1001);
        //this.close();
    };

    private displayTick = () =>
    {
        if (!this.isPaused)
        {
            this.progressBarAnimationTicks--;
            this.progressElement.value = this.progressBarAnimationTicks / MessageManager.progressBarAnimationLength * 100;
        }

        if (this.progressElement.value > 0)
        {
            window.requestAnimationFrame(this.displayTick);
        }
        else
        {
            this.close();
        }
    };

    private closeTick = () =>
    {
       if (this.closeAnimationTicks > 0)
       {
            let percentage = (MessageManager.closeAnimationLength - this.closeAnimationTicks) / MessageManager.closeAnimationLength;
            let percentageInverged = 1 - percentage;

            let topMargin = 0;
            if (this.element.previousElementSibling != null)
            {
                topMargin = parseFloat(window.getComputedStyle(this.element.previousElementSibling).marginBottom);
            }

            let bottomMargin = parseFloat(window.getComputedStyle(this.element).marginBottom);

            this.element.style.marginTop = `${-(this.element.offsetHeight + topMargin + bottomMargin) * percentage}px`;

            this.element.style.opacity = percentageInverged.toString();

            this.closeAnimationTicks--;
            window.requestAnimationFrame(this.closeTick);
       }
       else
       {
            this.remove();
       }
    }

    private close()
    {
        this.isClosing = true;
        this.element.classList.add('closing');
        this.closeTick();
    }

    private remove()
    {
        this.element.parentElement.removeChild(this.element);
    }
}