import BaseComponent from "../base";


export default class CommentsManager extends BaseComponent
{
    private replyButtons: NodeListOf<HTMLElement>;
    private replyFormTemplate: string;
    public static readonly selector = '[data-hook-comments-manager]';

    constructor(element: HTMLElement)
    {
        super(element);
        this.init();
    }
    
    protected init()
    {
        this.replyButtons = this.element.querySelectorAll('[data-is-insert-reply-button]');
        this.replyFormTemplate = this.element.querySelector('[data-is-reply-form-template]').textContent;
        this.bindEvents();
    }

    protected bindEvents()
    {
        for (let button of this.replyButtons)
        {
            button.addEventListener('click', this.onReplyButtonClick);
        }
    }
    
    private onReplyButtonClick = (e: MouseEvent) =>
    {
        let button = e.currentTarget as HTMLElement;
        console.log(e, button)

        let wrapper = document.createElement('div');
        wrapper.innerHTML = this.replyFormTemplate;

        button.parentElement.parentElement.parentElement.appendChild(wrapper);
    };

}