import CoreApp from "../../core/frontend/ts/index";
import CommentsManager from "../../core/frontend/ts/components/comments/index";
import PaginationManager from "../../core/frontend/ts/components/paginator/index";
import MessageManager from "../../core/frontend/ts/components/messages/index";
import { BulmaFileInputManager } from "../../core/frontend/ts/components/bulma/file-input-manager";
import { NavbarManager } from "../../core/frontend/ts/components/bulma/navbar";
import { FormsetManager } from "../../core/frontend/ts/components/form/formset";
import Ajax from "../../core/frontend/ts/components/ajax/index";
import ModalManager from "../../core/frontend/ts/components/bulma/modal-manager";
import ClipboardButtonManager from "../../core/frontend/ts/components/clipboard/index";
import TournamentHub from "./tournament-hub";


class App extends CoreApp
{
    private foo()
    {
        setTimeout(() =>
        {

        }, 1000);
    }
}

// CommentsManager.registerAll();
PaginationManager.registerAll();
MessageManager.registerAll();
BulmaFileInputManager.registerAll();
NavbarManager.registerAll();
FormsetManager.registerAll();
ModalManager.registerAll();
ClipboardButtonManager.registerAll();

export {
    PaginationManager, CommentsManager, Ajax, TournamentHub
}

let eventStack = document.querySelector('[data-hook-event-auto-updater]');
if (eventStack != null)
{
    let url = eventStack.getAttribute('data-url');

    let isUpdate = eventStack.getAttribute('data-update') == '1' ? true : false;

    console.log(url);

    const update = () =>
    {
        let newestId = eventStack.getAttribute('data-newest-id');
        
        Ajax.get(`${url}&latest-id=${newestId}`).then(html =>
        {
            let wrapper = document.createElement('div');
            wrapper.innerHTML = html;
            let parent = eventStack.parentElement;
            parent.removeChild(eventStack);
            eventStack = wrapper.firstElementChild as HTMLElement;
            parent.appendChild(eventStack);       

            
            let wasUpdated = eventStack.getAttribute('data-was-updated') == '1';
            if (wasUpdated)
            {
                eventStack.classList.add('was-updated');   
            }
            queueNext();
        });
    };

    const queueNext = () => 
    { 
        window.setTimeout(update, 2000);
        console.log('queued');
    };

    if (isUpdate)
    {
        queueNext();
    }
}
else
{
    console.log('no event stack')
}


document.addEventListener('DOMContentLoaded', () =>
{
    const bracketPlayers = document.querySelectorAll('[data-player-id]');
    const hoverClassName = 'has-mouse-over-highlight';

    const onMouseEnter = (e: MouseEvent) =>
    {
        let id = (e.currentTarget as HTMLElement).getAttribute('data-player-id');
        for (let player of bracketPlayers)
        {
            let add = player.getAttribute('data-player-id') == id;
            player.classList[add ? 'add' : 'remove'](hoverClassName);
        }
    };

    const onMouseLeave = (e: MouseEvent) =>
    {
        for (let player of bracketPlayers)
        {
            player.classList.remove(hoverClassName);
        }
    };

    for (let player of bracketPlayers)
    {
        player.addEventListener('mouseenter', onMouseEnter);
        player.addEventListener('mouseleave', onMouseLeave);
    }
});