export default abstract class BaseComponent
{
    protected element: HTMLElement;
    public static readonly selector: string;

    constructor(element: HTMLElement)
    {
        this.element = element;
    }

    public static registerAll()
    {
        document.addEventListener('DOMContentLoaded', () =>
        {
            this.registerAllNow();
        });
    }

    public static registerAllNow()
    {
        let elements = document.querySelectorAll(this.selector);
        let results = [];
        for(let element of elements)
        {
            let attribute = `data-is-${this.selector.replace('[', '--').replace(']', '--')}-registered`;

            if (element.hasAttribute(attribute))
            {
                continue;
            }
            element.setAttribute(attribute, '');
            results.push(new (<any>this)(element));
        }
    }
}