import BaseComponent from "../base";

export default class PaginationManager extends BaseComponent
{
    public static readonly selector = '[data-attach-pagination-manager]';
    private pageNumberField: HTMLInputElement;
    private pageSelectElements: NodeListOf<HTMLSelectElement>;
    private pageButtons: NodeListOf<HTMLButtonElement>;
 
    constructor(element: HTMLElement)
    {
        super(element);
        this.init();
    }

    private init()
    {
        this.pageSelectElements = this.element.querySelectorAll('[data-is-page-select]');
        this.pageNumberField = this.element.querySelector('[data-is-page-number-field]');
        this.pageButtons = this.element.querySelectorAll('[data-is-page-button]');
        this.bindEvents();
    }

    private bindEvents()
    {
        for (let select of this.pageSelectElements)
        {
            select.addEventListener('change', this.onPageSelectChanged);
        }

        for(let button of this.pageButtons)
        {
            button.addEventListener('click', this.onPageButtonClicked);
        }
    }

    private onPageButtonClicked = (e: MouseEvent) =>
    {
        let button = e.currentTarget as HTMLButtonElement;
        this.pageNumberField.value = button.value;
        this.pageNumberField.form.submit();
    };

    private onPageSelectChanged = (e: Event) =>
    {
        let select = e.currentTarget as HTMLSelectElement;
        let pageNumber = parseInt(select.options[select.selectedIndex].value);

        if (!isNaN(pageNumber))
        {
            this.pageNumberField.value = pageNumber.toString();
            this.pageNumberField.form.submit();
        }  
    };
}