
export default class App
{
    constructor()
    {
        window.addEventListener('DOMContentLoaded', this.onDomContentLoaded);
    }

    protected onDomContentLoaded = () =>
    {
    
    };
}