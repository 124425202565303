import BaseComponent from "../base";

export class NavbarManager extends BaseComponent
{
    public static readonly selector = '[data-hook-navbar-manager]';
    private navbarMenu: HTMLElement;
    private toggleButton: HTMLInputElement;

    constructor(element: HTMLElement)
    {
        super(element);
        this.init();
        this.bindEvents();
    }

    private init()
    {
        this.toggleButton = this.element.querySelector('[data-is-navbar-toggle-button]');
        this.navbarMenu = this.element.querySelector('[data-is-navbar-menu]');
    }

    private bindEvents()
    {
        this.toggleButton.addEventListener('click', e => this.onToggleButtonClick(e));
    }

    private onToggleButtonClick(e: MouseEvent)
    {
        this.navbarMenu.classList.toggle('is-active');
        this.toggleButton.classList.toggle('is-active');

        if (this.element.hasAttribute('data-is-main-navabar'))
        {
            document.body.classList.toggle('has-navbar-expanded');
        }
    }
}