import BaseComponent from "../base";
import Ajax from "../ajax/index";


export default class ModalManager extends BaseComponent
{
    protected element: HTMLButtonElement;
    public static readonly selector = '[data-hook-modal-manager]';
    private name: string;

    constructor(element: HTMLElement)
    {
        super(element);
        this.init();
        this.bindEvents();
        this.element.removeAttribute('disabled');
    }

    private init()
    {
        this.name = this.element.getAttribute('data-modal-name');
    }

    private bindEvents()
    {
        this.element.addEventListener('click', this.onClicked);
    }

    private onClicked = (e: MouseEvent) =>
    {
        e.preventDefault();
        this.element.classList.add('is-loading');
        this.element.setAttribute('disabled', '');

        let url = `?modal=${this.name}`;

        Ajax.get(url).then((html) =>
        {
            let wrapper = document.createElement('div');
            wrapper.innerHTML = html;

            let modal = wrapper.querySelector('.modal') as HTMLElement;
            document.body.appendChild(modal);

            window.setTimeout(() =>  modal.classList.add('is-active'), 10);
            
            let closeButton = modal.querySelector('[data-is-modal-close-button]');
            let cancelButton = modal.querySelector('[data-is-modal-cancel-button]');
            let continueButton = modal.querySelector('[data-is-modal-continue-button]');
            let background = modal.querySelector('[data-is-modal-background]');

            if (closeButton)
                closeButton.addEventListener('click', e => this.closeModal(modal, false));

            if (cancelButton)
                cancelButton.addEventListener('click', e => this.closeModal(modal, false));

            if (continueButton)
                continueButton.addEventListener('click', e => this.closeModal(modal, true));

            if (background)
                background.addEventListener('click', e => this.closeModal(modal, false));            
            
            
            let onKeyUp = (e: KeyboardEvent) =>
            {
                if (e.keyCode == 27)
                {
                    window.removeEventListener('keyup', onKeyUp);
                    this.closeModal(modal, false);
                }
            };
            
            window.addEventListener('keyup', onKeyUp);

        });
    };

    private onDocumentKeyUp(e: KeyboardEvent)
    {

    }

    private closeModal(modal: HTMLElement, submit: boolean)
    {
        modal.classList.add('is-closing');
        window.setTimeout(() =>
        {
            modal.parentElement.removeChild(modal);
            this.element.classList.remove('is-loading');
            this.element.removeAttribute('disabled');

            if (submit)
            {
                if (this.element.name == 'DELETE')
                {
                    let deleteInput = this.element.parentElement.querySelector('[data-is-delete-field]') as HTMLInputElement;
                    deleteInput.value = '1';
                    this.element.parentElement.appendChild(deleteInput);
                }
                this.element.form.submit();
            }
        }, 301);
    }
}